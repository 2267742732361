export const MAILBOX_ADD = 'MAILBOX_ADD';
export const MAILBOX_ADD_SUCCESS = 'MAILBOX_ADD_SUCCESS';
export const MAILBOX_ADD_FAIL = 'MAILBOX_ADD_FAIL';

export const MAILBOX_UPDATE_REQUEST = 'MAILBOX_UPDATE_REQUEST';
export const MAILBOX_UPDATE_SUCCESS = 'MAILBOX_UPDATE_SUCCESS';
export const MAILBOX_UPDATE_FAIL = 'MAILBOX_UPDATE_FAIL';

export const MAILBOX_REMOVE = 'MAILBOX_REMOVE';
export const MAILBOX_REMOVE_SUCCESS = 'MAILBOX_REMOVE_SUCCESS';
export const MAILBOX_REMOVE_FAIL = 'MAILBOX_REMOVE_FAIL';

export const MAILBOX_DOMAIN_STATISTICS_REQUEST = 'MAILBOX_DOMAIN_STATISTICS_REQUEST';
export const MAILBOX_DOMAIN_STATISTICS_REQUEST_SUCCESS = 'MAILBOX_DOMAIN_STATISTICS_REQUEST_SUCCESS';
export const MAILBOX_DOMAIN_STATISTICS_REQUEST_FAIL = 'MAILBOX_DOMAIN_STATISTICS_REQUEST_FAIL';

export const MAILBOX_DOMAIN_LISTS_REQUEST = 'MAILBOX_DOMAIN_LISTS_REQUEST';
export const MAILBOX_DOMAIN_LISTS_REQUEST_SUCCESS = 'MAILBOX_DOMAIN_LISTS_REQUEST_SUCCESS';
export const MAILBOX_DOMAIN_LISTS_REQUEST_FAIL = 'MAILBOX_DOMAIN_LISTS_REQUEST_FAIL';

export const MAILBOX_ID_STATISTICS_REQUEST = 'MAILBOX_ID_STATISTICS_REQUEST';
export const MAILBOX_ID_STATISTICS_REQUEST_SUCCESS = 'MAILBOX_ID_STATISTICS_REQUEST_SUCCESS';
export const MAILBOX_ID_STATISTICS_REQUEST_FAIL = 'MAILBOX_ID_STATISTICS_REQUEST_FAIL';

export const MAILBOX_CONNECT_REQUEST = 'MAILBOX_CONNECT_REQUEST';
export const MAILBOX_CONNECT_SUCCESS = 'MAILBOX_CONNECT_SUCCESS';
export const MAILBOX_CONNECT_FAIL = 'MAILBOX_CONNECT_FAIL';

export const MAILBOX_LISTS_LEADERBOARD_REQUEST = 'MAILBOX_LISTS_LEADERBOARD_REQUEST';
export const MAILBOX_LISTS_LEADERBOARD_SUCCESS = 'MAILBOX_LISTS_LEADERBOARD_SUCCESS';
export const MAILBOX_LISTS_LEADERBOARD_FAIL = 'MAILBOX_LISTS_LEADERBOARD_FAIL';

export const MAILBOX_VALIDATE_REQUEST = 'MAILBOX_VALIDATE_REQUEST';
export const MAILBOX_VALIDATE_SUCCESS = 'MAILBOX_VALIDATE_SUCCESS';
export const MAILBOX_VALIDATE_FAIL = 'MAILBOX_VALIDATE_FAIL';